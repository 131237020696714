import React, { useState, useRef } from 'react';
import startImage from "../assets/images/play-btn-white.svg";
import stopImage from "../assets/images/stopbtn.png";
import ShowModal from './ShowModal';
import ErrorMessage from "../assets/images/error-mesgg.svg";

const AudioPlayer = ({ audioUrl }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const togglePlay = () => {
        if(audioUrl !== '') {
            if (!audioRef.current) {
                setShowModal(true);
                setErrorMessage("Audio element is not available.");
                return;
            }
            if (audioRef.current.paused) {
                audioRef.current.play();
                setIsPlaying(true);
            } else {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        }
        else {
            setShowModal(true);
            setErrorMessage("Audio URL is not available.");
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <>
        <div>
            {audioUrl && <audio ref={audioRef} src={audioUrl} />}
            {isPlaying ? (
                <div href="/" className="stop-btn player" onClick={(event) => { event.preventDefault(); togglePlay(); }}>
                    <img src={stopImage} alt="Stop" />
                </div>
            ) : (
                <a href="/" className="play-button-blue" onClick={(event) => { event.preventDefault(); togglePlay(); }}>
                    <img src={startImage} alt="Play" />
                </a>
            )}
        </div>
        {showModal && (<ShowModal showModal={showModal} handleCloseModal={handleCloseModal} AddClass={'error-message-mainbox fade'}>
                <div class="error-mesg-main">
                    <div class="del-box">
                        <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div class="continoue">
                            <a href="/" onClick={(e) => {
                                e.preventDefault();
                                setShowModal(false);
                            }}>Continue</a>
                            </div>
                    </div>
                </div>
            </ShowModal>
            )}
        </>
    );
};

export default AudioPlayer;
