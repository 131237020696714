// React component (e.g., AudioUploader.js)
import React, { useState } from 'react';
import useHttpService from '../HttpService';
import ReactJsonPretty from 'react-json-pretty';
import LoadingSpinner from './LoadingSpinner';


const AudioUploader = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [fileIds, setFileIds] = useState([]);
    const [Key, setKey] = useState(null);
    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const httpService = useHttpService();

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleUpload = async () => {
        setIsLoading(true);

        if (!selectedFiles || selectedFiles.length === 0) {
            console.error("No files selected");
            setIsLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            Array.from(selectedFiles).forEach((file) => {
                formData.append('files', file);
            });

            const response = await httpService.post(`api/audio/upload`, formData);

            const s3Keys = response.data.s3Keys || [];

            if (s3Keys.length > 0) {
                setFileIds([...fileIds, ...s3Keys]);
            } else {
                console.error("Failed to get S3 keys from the server response");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleConcatenate = async () => {
        try {
            setIsLoading(true);
            const response = await httpService.post(`api/audio/concatenate`, fileIds);
            setKey(response.data.s3KeyValue);
            setIsLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownload = async () => {
        try {
            const preSignedUrlResponse = await httpService.post(`api/audio/getPresignedUrl?s3Key=${Key}`);
            const preSignedUrl = preSignedUrlResponse.data.url;

            fetch(preSignedUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'filename.mp3'; // replace with the desired filename and extension
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => console.error('Error downloading file:', error));
        } catch (error) {
            console.error('Error getting pre-signed URL:', error);
        }
    };

    const handleTranscribe = async () => {
        try {
            setIsLoading(true);
            const response = await httpService.post(`api/audio/transcribe?s3Key=${Key}`);
            setResponse(response.data);
            const transcriptionResult = response.data;
            console.log('Transcription Result:', transcriptionResult);
            setIsLoading(false);
        } catch (error) {
            console.error('Transcription Error:', error);
            if (error.response) {
                console.error('Detailed Error Response:', error.response.data);
            }
        }
    };



    return (
        <div>
            <input type="file" onChange={handleFileChange} multiple />
            <button onClick={handleUpload}>Upload</button>
            {fileIds && fileIds.length > 0 && ( <button onClick={handleConcatenate}>Concatenate</button>)}
            {Key != null && (<button onClick={handleDownload}>Download Concatenated</button>) }
            {Key != null && (<button onClick={handleTranscribe}>Transcribe</button>)}
            {Object.keys(response).length > 0 && (<ReactJsonPretty id="json-pretty" json={response} />) }
            {isLoading && <LoadingSpinner />}
        </div>
    );
};

export default AudioUploader;
