import spinnerImg from '../assets/images/loading-spinner.gif';

const LoadingSpinner = () => {
    return (
        <div className="spinner-main-box">
            <div className="spinner-main">
                <img src={spinnerImg} alt="" />
            </div>
        </div>
    );
}

export default LoadingSpinner;