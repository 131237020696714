import { useForm } from 'react-hook-form';
import { validateEmail } from '../HelperMethods';
import useHttpService from '../HttpService';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { toast } from "react-toastify";
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const httpService = useHttpService();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        var contactObj = {
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message,
        };
        setIsLoading(true);
        httpService.post('/api/accounts/contactUs', contactObj)
            .then(data => {
                toast.success('Email has been sent.')
                navigate('/');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <section className="contact-us-main">
                <div className="container">
                    <h2 className="contact-heading">Contact Us</h2>
                    <p className="para">Lorem ipsum dolor sit amet consectetur. Purus arcu vitae amet cursus aliquet integer viverra viverra elementum.</p>
                    <div className="contactus-white-bg">
                        <h2>Get in Touch</h2>
                        <form className="contact-inform form contact-us-formss" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-control">
                                <input {...register('name', { required: true })} type="text" className="form-input" />
                                <label htmlFor="name" className="form-label">Name</label>
                                {errors.name?.type === 'required' && <p className="text-danger">Name is required!</p>}
                            </div>
                            <div className="form-control">
                                <input {...register('email', { required: true, validate: validateEmail })} type="text" className="form-input" />
                                <label htmlFor="email" className="form-label">Email</label>
                                {errors.email?.type === 'required' && <p className="text-danger">Email is required!</p>}
                                {errors.email?.type === 'validate' && <p className="text-danger">Email format is not correct!</p>}
                            </div>
                            <div className="form-control subject-bar">
                                <input {...register('subject', { required: true })} type="text" className="form-input" />
                                <label htmlFor="subject" className="form-label">Subject</label>
                                {errors.subject?.type === 'required' && <p className="text-danger">Subject is required!</p>}
                            </div>
                            <div className="form-group full contactMsg" id="cancellationNotesContainer">
                                <textarea {...register('message', { required: true })} maxLength="500" id="cancellationNotes"></textarea>
                                <label htmlFor="message">Message</label>
                                {errors.message?.type === 'required' && <p className="text-danger">Message is required!</p>}
                            </div>
                            <div className="submit-button">
                                <button type="submit" className="login-button send-button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default Contact;