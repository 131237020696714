import AllLessons from './AllLessons';
import UserLessons from './UserLessons';
const Dashboard = () => {
    return (
        <>
            <UserLessons />
            <AllLessons />
        </>
    )
}

export default Dashboard;