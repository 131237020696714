import React from 'react';
import { Modal } from 'react-bootstrap';
import cross from '../assets/images/gray-cross.svg'

const CustomModal = ({ isOpen, closeWithIcon, children, classes = '' }) => {
    return (
        <div>
            <Modal className={`modal-login ${classes}`} backdrop="static" show={isOpen} onHide={closeWithIcon}>
                <Modal.Header>
                    <div className="close-btn" onClick={closeWithIcon}>
                        <button type="button">
                            <img src={cross} alt="" />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CustomModal;