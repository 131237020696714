import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToasterManager = () => {
    return <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
    />
}


export default ToasterManager;
