import plusImage from '../../assets/images/plus.svg';
import deleteImage from '../../assets/images/del-btn.svg';
import { useEffect, useState } from 'react';
import useHttpService from '../../HttpService';
import { toast } from "react-toastify";
import CustomModal from '../../components/CustomModal';
import Pagination from '../Pagination';
import englishImage from '../../assets/images/english.svg';
import magnifiedGlassImage from '../../assets/images/magnified-glass.svg';
import Tooltip from '../Tooltip';
import barsImage from '../../assets/images/bars.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ErrorMessage from "../../assets/images/error-mesgg.svg";
import ShowModal from "../ShowModal";

const SuggestedLessons = ({ lessonId }) => {
    const [suggestedLessons, setSuggestedLessons] = useState(null);
    const [showSuggestedLessons, setShowSuggestedLessons] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [lessonsToSuggest, setLessonsToSuggest] = useState(null);
    const [checkedIds, setCheckedIds] = useState([]);
    const [suggestedLessonCount, setSuggestedLessonCount] = useState(0);
    const httpService = useHttpService();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const levelDots = {
        1: '',
        2: 'blue',
        3: 'musterd',
        4: 'brown',
        5: 'redd'
    };

    const pageItems = [6, 15, 30];
    const queryObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        SearchText: '',
        LessonId: lessonId
    };

    useEffect(() => {
        getSuggestedLessons(queryObj.PageNumber, queryObj.PageSize);
    }, [lessonId]);

    const getSuggestedLessons = (pageNumber, pageSize) => {
        httpService.post(`/api/lessons/suggested-lessons`, { pageNumber, pageSize, SearchText: '', lessonId })
            .then(res => {
                setSuggestedLessons(res.data.data);
                setSuggestedLessonCount(res.data.totalCount);
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else
                    setErrorMessage('Something went wrong, please try again or contact support!');

            });
    };

    const getLessonsToSuggest = (pageNumber, pageSize) => {
        queryObj.PageNumber = pageNumber;
        queryObj.PageSize = pageSize;

        httpService.post('/api/lessons/all', queryObj)
            .then((response) => {
                setLessonsToSuggest(response.data.data);
                setTotalCount(response.data.totalCount);
                setShowSuggestedLessons(true);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                } else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                }
                setShowModal(true);
                return;
            });
    };

    const handleTitleChange = (event) => {
        queryObj.SearchText = event.target.value;
        getLessonsToSuggest(queryObj.PageNumber, queryObj.PageSize);
    };

    const handleDelete = (event, suggestedLessonId) => {
        event.preventDefault();
        httpService.remove(`/api/lessons/remove-suggested-lesson?lessonId=${lessonId}&suggestedLessonId=${suggestedLessonId}`)
            .then(res => {
                getSuggestedLessons(queryObj.PageNumber, queryObj.PageSize); // Refresh suggested lessons
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else
                    setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            });
    };

    const handleAddSuggestedLesson = (event) => {
        event.preventDefault();
        getLessonsToSuggest(queryObj.PageNumber, queryObj.PageSize);
    };

    const closeModal = () => {
        setShowSuggestedLessons(false);
    };

    const handleCheckboxChange = (id) => {
        if (checkedIds.includes(id))
            setCheckedIds(checkedIds.filter((item) => item !== id));
        else
            setCheckedIds([...checkedIds, id]);
    };

    const handleAdd = (event) => {
        event.preventDefault();
        if (checkedIds.length > 0) {
            const ids = checkedIds.join(',');
            httpService.post(`/api/lessons/suggest-lessons?lessonId=${lessonId}&suggestedLessonIds=${ids}`)
                .then(res => {
                    setCheckedIds([]);
                    getSuggestedLessons(queryObj.PageNumber, queryObj.PageSize); // Refresh suggested lessons
                    setShowSuggestedLessons(true);
                })
                .catch(err => {
                    if (err?.response?.data)
                        setErrorMessage(err.response.data);
                    else if (err.errors)
                        setErrorMessage(err.errors[0]);
                    else
                        setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowModal(true);
                    return;
                });
        }
    };

    const handleOrder = (query) => {
        httpService.put(`/api/lessons/update-suggested-ordernumber`, query)
            .then(res => {
                //getSuggestedLessons(queryObj.PageNumber, queryObj.PageSize);
                //getLessonsToSuggest(queryObj.PageNumber, queryObj.PageSize);
            })
            .catch(err => {
            });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorderedItems = Array.from(suggestedLessons);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        setSuggestedLessons(reorderedItems);
        //const reorderedIds = reorderedItems.map(item => item.suggestedLessonId);
        handleOrder(reorderedItems);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="suggested-lessons suggested-fix">
            <div className="my-lessons">
                <h2>Suggested Lessons</h2>
                {suggestedLessonCount > 0 && <p className="suggested-bubble-count">{suggestedLessonCount}</p>}
                <Tooltip text="Add Lessons">
                    <div className="dropdown my-lessons-dropdown">
                        <a className="btn btn-secondary dropdown-toggle cursor" href="/" onClick={handleAddSuggestedLesson}>
                            <img src={plusImage} alt="" />
                        </a>
                    </div>
                </Tooltip>
            </div>
            {suggestedLessons && suggestedLessons.map(lesson => (
                <div className="lesson-title-rightBox" key={lesson.suggestedLessonId}>
                    <div className="lesson-title-box">
                        <div className="lesson-flag-main">
                            <div className="lesson-left-flag">
                                <h2>{lesson.name}</h2>
                                <Tooltip text="English">
                                    <img src={englishImage} alt="" />
                                </Tooltip>
                                <span></span>
                            </div>
                            <div className="lesson-right-button">
                                <div className="lesson-del-button">
                                    <a href="/" onClick={(event) => handleDelete(event, lesson.suggestedLessonId)}>
                                        <img src={deleteImage} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="lesson-description">
                            <span><label>Description:</label> {lesson.description}</span>
                        </div>
                    </div>
                </div>
            ))}

            <CustomModal isOpen={showSuggestedLessons} closeModal={closeModal} closeWithIcon={closeModal} classes={'modal modal-login work-terminlogy production-popup-main fade add-suggested-lessons add-suggested-popup-changes'}>
                <div className="whiteBox-heading terminology">
                    <h2>Add Suggested Lessons</h2>
                </div>
                <div className="suggested-main-body">
                    <div className="suggested-main-left">
                        <div className="add-suggested-lessonsMain">
                            <form>
                                <div className="mb-3 lesson-forms">
                                    <input type="email" className="form-control" placeholder="Search" onChange={handleTitleChange} />
                                    <img src={magnifiedGlassImage} className="magnified" alt="" />
                                </div>
                            </form>
                            <div className="add-suggested-popUp-main">
                                {lessonsToSuggest && lessonsToSuggest.map((lesson) => (
                                    <div className="lesson-title-box" key={lesson.lessonId}>
                                        <div className="lesson-flag-main">
                                            <div className={`lesson-left-flag ${levelDots[lesson.levelId]}`}>
                                                <h2>{lesson.name}</h2>
                                                <Tooltip text="English">
                                                    <img src={englishImage} alt="" />
                                                </Tooltip>
                                                {[...Array(lesson.levelId)].map((_, index) => (
                                                    <span key={index}></span>
                                                ))}
                                            </div>
                                            <div className="lesson-right-button">
                                                <div className="suggested-lesson-checkBoxes">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={checkedIds.includes(lesson.lessonId)}
                                                            onChange={() => handleCheckboxChange(lesson.lessonId)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lesson-description">
                                            <span><label>Description:</label> {lesson.description}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination pageNo={queryObj.PageNumber} size={queryObj.PageSize} count={totalCount} perPageItems={pageItems} onChange={getLessonsToSuggest} />
                            <div className="add-button-suggested-Lessons">
                                <a href="/" onClick={handleAdd}>Add</a>
                            </div>
                        </div>
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="suggested-lessons">
                            {(provided) => (
                                <>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="suggested-main-right"
                                    >
                                        {suggestedLessons && suggestedLessons.map((lesson, index) => (
                                            < Draggable
                                                key={lesson.suggestedLessonId}
                                                draggableId={lesson.suggestedLessonId.toString()}
                                                index={index}
                                            >
                                                {(provided) => (<div
                                                    className="sugges-main-right-boxes"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <div className="lesson-title-leftt lesson-left-flag">
                                                        <div  {...provided.dragHandleProps}>
                                                            <img id="drag" src={barsImage} alt="" />
                                                        </div>
                                                        <div className={`lesson-left-flag ${levelDots[lesson.levelId]}`}>
                                                            <h2>{lesson.name}</h2>
                                                            <Tooltip text="English">
                                                                <img src={englishImage} alt="" />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="lesson-title-rightt">
                                                        <div className="lesson-del-button">
                                                            <a href="/" onClick={(event) => handleDelete(event, lesson.suggestedLessonId)}>
                                                                <img src={deleteImage} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                                }
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        {suggestedLessons && suggestedLessons.length > 0 && (
                                            <Pagination pageNo={queryObj.PageNumber} size={queryObj.PageSize} count={suggestedLessonCount} perPageItems={pageItems} onChange={getSuggestedLessons} />
                                        )}
                                    </div>
                                </>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </CustomModal >
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </div >
    );
}

export default SuggestedLessons;
