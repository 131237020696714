import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

const Layout = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <Container tag="div">
         {children}
      </Container>
    </div>
  );
}

export default Layout;