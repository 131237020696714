/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import useHttpService from '../HttpService';
import dropdownImg from '../assets/images/my-lessons-dropdown.svg';
import searchIcon from '../assets/images/magnified-glass.svg';
import englishImg from '../assets/images/usa-image.png';
import { Link, useNavigate } from 'react-router-dom';
import { getLoggedInUserId, levelDotsImages, difficultyData } from '../HelperMethods';
import LoadingSpinner from '../components/LoadingSpinner'
import Pagination from './Pagination';
import { toast } from "react-toastify";
import Tooltip from './Tooltip';
import ShowModal from "./ShowModal";
import ErrorMessage from "../assets/images/error-mesgg.svg";

const AllLessons = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [lessons, setLessons] = useState([]);
    const httpService = useHttpService();
    const [searchValue, setSearchValue] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [hasFilter, setHasFilter] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const loggedInUserId = getLoggedInUserId();
    const navigate = useNavigate();

    const pageItems = [6, 12, 24];
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        SearchText: '',
        LevelId: 0,
        UserId: loggedInUserId,
        State: 'Published'
    };

    useEffect(() => {
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    }, []);

    const getLessons = (pageNumber, pageSize) => {
        setIsLoading(true);
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        requestObj.LevelId = requestObj.LevelId !== 0 ? [requestObj.LevelId] : null;
        requestObj.State = [requestObj.State];
        httpService
            .post('/api/lessons/all', requestObj)
            .then((response) => {
                setLessons(response.data.data);
                setTotalCount(response.data.totalCount);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleSelect = (levelId) => {
        requestObj.LevelId = levelId;
        getLessons(requestObj.PageNumber, requestObj.PageSize);
        setHasFilter(true);
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        requestObj.SearchText = searchValue;
        requestObj.LevelId = 0;
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    };

    const handleJoinLesson = (lessonId, e) => {
        e.preventDefault();

        httpService.post(`/api/userLessons/join?userId=${loggedInUserId}&lessonId=${lessonId}`)
            .then(res => {
                navigate('/userLesson/' + res.data);
            })
            .catch(err => {
                setErrorMessage('Something went wrong please try again!');
                setShowModal(true);
            })
    };
    const handleClearResults = (event) => {
        debugger
        event.preventDefault();
        setSearchValue('');
        requestObj.LevelId = 0;
        requestObj.SearchText = '';
        getLessons(requestObj.PageNumber, requestObj.PageSize);
        setHasFilter(false); 
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <section className="all-lessons">
                <div className="container inner-container all-lessons-main">
                    <div className="row lessons-row">
                        <div className="col-md-6">
                            <div className="my-lessons">
                                <h2>All Lessons</h2>
                                <div className="dropdown my-lessons-dropdown">
                                    <a
                                        className="btn btn-secondary dropdown-toggle"
                                        href="/"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img className="dashbord-ico" src={dropdownImg} alt="" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        {difficultyData.map((filter) => (
                                            <Link
                                                className="dropdown-item"
                                                onClick={() => handleSelect(filter.value)}
                                                to="#"
                                                key={filter.value}
                                            >
                                                {filter.value}. {<img src={filter.image} alt="" />}
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                                {hasFilter && <div className="clear-filters"><a href="/" onClick={handleClearResults}>Clear Results</a></div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="search-bar-main">
                                <div className="form-floating dashbord-searchBar">
                                    <input
                                        autoComplete="off"
                                        className="form-control"
                                        id="searchTitle"
                                        placeholder="Search"
                                        value={searchValue}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="searchTitle" className="floating-labels">
                                        Search
                                    </label>
                                    <a onClick={handleSubmit} href="/">
                                        <img src={searchIcon} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whiteBox-Main">
                <div className="container inner-container">
                    <div className="row">
                        {lessons &&
                            lessons.map((lesson) => (
                                <div className="col-md-4" key={lesson.lessonId}>
                                    <a href="/" onClick={(e) => { handleJoinLesson(lesson.lessonId, e); }} className="card-link">
                                        <div className="terminology-mainnn">
                                            <div className="image-top-box">
                                                <img src={englishImg} alt="" className="country-flagg" />
                                                <img src={levelDotsImages[lesson.levelId]} alt="" className="diffculty-level" />
                                                <div className="white-fade"></div>
                                            </div>
                                            <div className="work-terminology-text">
                                                <Tooltip text={lesson.name}>
                                                    <h2>{lesson.name}</h2>
                                                </Tooltip>
                                                <p>{lesson.description}</p>
                                            </div>
                                            <div className="strt-leson">
                                                <span>Start Lesson</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))};
                    </div>
                </div>
            </section>
            {lessons && (
                <Pagination
                    pageNo={requestObj.PageNumber}
                    size={requestObj.PageSize}
                    count={totalCount}
                    perPageItems={pageItems}
                    onChange={getLessons}
                    addExtraClasses={false}
                    classes="main-page-pagination"
                />
            )}
            {isLoading && <LoadingSpinner />}
            {showModal && (<ShowModal showModal={showModal} handleCloseModal={handleCloseModal} AddClass={'error-message-mainbox fade'}>
                <div class="error-mesg-main">
                    <div class="del-box">
                        <img src={ErrorMessage}></img>
                        <h2>Error</h2>
                        <span>{errorMessage}</span>
                        <div class="continoue">
                            <a href="/" onClick={(e) => {
                                e.preventDefault();
                                setShowModal(false);
                            }}>Continue</a>
                        </div>
                    </div>
                </div>
            </ShowModal>
            )}
        </>
    );
};

export default AllLessons;
