import { useForm } from 'react-hook-form';
import { validateEmail } from '../HelperMethods';
import useHttpService from '../HttpService';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import CustomModal from './CustomModal';
import LoadingSpinner from './LoadingSpinner';
import Home from './Home';
import { toast } from "react-toastify";
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const ForgotPassword = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const httpService = useHttpService();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeWithIcon = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setIsLoading(true);
        httpService.post('/api/accounts/sendCode?email=' + data.email)
            .then(res => {
                toast.success('Verification code has been sent.')
                localStorage.setItem('verifyCodeToken', res.data);
                localStorage.setItem('verifyCodeEmail', data.email);
                closeModal();
                navigate('/verifyCode');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Home />
            <CustomModal isOpen={isModalOpen} closeModal={closeModal} closeWithIcon={closeWithIcon}>
                <h2>Forgot Password?</h2>
                <div className="form-main">
                    <p className="enter-email">Please enter your email to receive a verification code.</p>
                    <form className="contact-inform form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="create-new-pass">
                            <div className="form-control">
                                <input {...register('email', { required: true, validate: validateEmail })} type="text" className="form-input" />
                                <label htmlFor="email" className="form-label">Email</label>
                                {errors.email?.type === 'required' && <p className="text-danger">Email is required!</p>}
                                {errors.email?.type === 'validate' && <p className="text-danger">Email format is not correct!</p>}
                            </div>
                        </div>
                        <button type="submit" className="btn login-button send-button">Send</button>
                    </form>
                </div>
            </CustomModal>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default ForgotPassword;