// AutocompleteComponent.jsx

import React from "react";
import PropTypes from "prop-types";

const Autocomplete = ({ suggestions, onSuggestionClick }) => {
    return (
        <div className="autocomplete-dropdown">
            {suggestions.map((item) => (
                <div
                    key={item.lessonId}
                    className="autocomplete-item"
                    onClick={() => onSuggestionClick(item.name, item.lessonId)}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};

Autocomplete.propTypes = {
    suggestions: PropTypes.array.isRequired,
    onSuggestionClick: PropTypes.func.isRequired,
};

export default Autocomplete;
