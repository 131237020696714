/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import useHttpService from '../HttpService';
import { getLoggedInUserId, calculateResult, levelDotsImages, userLessonFileters } from '../HelperMethods';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import dropdownImg from '../assets/images/my-lessons-dropdown.svg';
import startImage from '../assets/images/play-btn-white.svg';
import englishImage from '../assets/images/english.svg';
import Tooltip from './Tooltip';
import Pagination from './Pagination';

const UserLessons = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userLessons, setUserLessons] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [levelId, setLevelId] = useState([0]);
    const [status, setStatus] = useState("");
    const [hasFilter, setHasFilter] = useState(false);
    const httpService = useHttpService();
    const userId = getLoggedInUserId();
    const navigate = useNavigate();

    const pageItems = [10, 20, 30];
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        UserId: userId,
        LevelId: levelId,
        Status: status,
    };

    useEffect(() => {
        GetLessons(requestObj.PageNumber, requestObj.PageSize);
    }, [requestObj.PageNumber, requestObj.PageSize, levelId, status]);

    const GetLessons = (pageNumber, pageSize) => {
        setIsLoading(true);
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        httpService.post('/api/userLessons/all', requestObj)
            .then(res => {
                setUserLessons(res.data.data);
                setTotalCount(res.data.totalCount);
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleSelect = (item, type) => {
        if (type === "status") {
            setStatus(item);
        } else {
            setLevelId([item]);
        }
        setHasFilter(true);
    };

    const handleStartLesson = (userLessonId, e) => {
        e.preventDefault();
        navigate('/userLesson/' + userLessonId);
    };
    const handleClearFilters = (event) => {
        event.preventDefault();
        setLevelId([0]);
        setStatus("")
        requestObj.PageNumber = 1;
        requestObj.PageSize = pageItems[0];
        requestObj.LevelId = [0];
        requestObj.Status = "";
        GetLessons(requestObj.PageNumber, requestObj.PageSize);
        setHasFilter(false);
    };

    return (
        <>
            <section className="dashbord-main">
                <div className="container inner-container">
                    <div className="my-lessons">
                        <h2>My Lessons</h2>
                        <div className="dropdown my-lessons-dropdown">
                            <a className="btn btn-secondary dropdown-toggle"
                                href="dropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <img className="dashbord-ico" src={dropdownImg} alt="" />
                            </a>
                            <ul className="dropdown-menu">
                                {userLessonFileters.map((filter, index) => (
                                    <React.Fragment key={filter.value}>
                                        <Link
                                            className="dropdown-item"
                                            onClick={() => handleSelect(filter.value, filter.type)}
                                            to="#"
                                        >
                                            {filter.name}{filter.type === "levelId" && '.'} <img src={filter.image} alt="" />
                                        </Link>
                                        {filter.value === "5" && <hr />}
                                    </React.Fragment>
                                ))}
                            </ul>

                        </div>
                        {hasFilter && <div className="clear-filters"><a href="/" onClick={handleClearFilters}>Clear Results</a></div>}
                    </div>
                </div>
            </section>

            <section className="table-main">
                <div className="container inner-container">
                    <div className="table-responsiveness">
                        <div className="table-inside">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Lesson</th>
                                        <th>Progress</th>
                                        <th>Level</th>
                                        <th>Score</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userLessons.length === 0 && <tr><td colSpan="5" style={{ textAlign: 'left' }}>You have not joined any lesson yet!</td></tr>}
                                    {userLessons.map((lesson) => (
                                        <tr key={lesson.userLessonId}>
                                            <td>
                                                <Tooltip text="English">
                                                    <img src={englishImage} alt="" className="table-image" />
                                                </Tooltip>
                                                <Tooltip text={lesson.lessonName}>
                                                    <span>{lesson.lessonName}</span>
                                                </Tooltip>
                                            </td>
                                            <td>{lesson.progress}</td>
                                            <td><img src={levelDotsImages[lesson.levelId]} alt="" /></td>
                                            <td>{lesson.score !== '-' ? calculateResult(parseInt(lesson.score)) : lesson.score}</td>
                                            <td>
                                                <Tooltip text={lesson.progress === "Not Started" ? "Start" : "Retake"}>
                                                    <a href="/" className="join-lesson" onClick={(e) => { handleStartLesson(lesson.userLessonId, e) }}>
                                                        <img src={startImage} alt="" />
                                                    </a>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {userLessons && <Pagination pageNo={requestObj.PageNumber} size={requestObj.PageSize} count={totalCount} perPageItems={pageItems} onChange={GetLessons} addExtraClasses={false} classes="main-page-pagination paginaion-left" />}
            {isLoading && <LoadingSpinner />}
        </>
    );
};

export default UserLessons;
