import { useForm } from 'react-hook-form';
import { validateEmail } from '../HelperMethods';
import useHttpService from '../HttpService';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import CustomModal from './CustomModal';
import LoadingSpinner from './LoadingSpinner';
import { AuthContext } from '../AuthContext';
import Home from './Home';
import { toast } from "react-toastify";
import eyeImage from '../assets/images/eye.svg';
import jwt_decode from 'jwt-decode';
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const Login = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { setIsLoggedIn } = useContext(AuthContext);
    const httpService = useHttpService();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeWithIcon = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setIsLoading(true);
        httpService.get('/api/accounts/login?email=' + data.email + '&password=' + data.password)
            .then(data => {
                localStorage.setItem('jwtToken', data.headers.authorization);
                setIsLoggedIn(true);
                closeModal();
                toast.success('Login successful');
                const decodedToken = jwt_decode(data.headers.authorization);
                const navigateTo = decodedToken?.role === 'Admin' ? '/lessonAdmin' : '/dashboard';
                navigate(navigateTo);
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const navigateTo = (path, event) => {
        event.preventDefault();
        closeModal();
        navigate(path);
    }

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <Home />
            <CustomModal isOpen={isModalOpen} closeModal={closeModal} closeWithIcon={closeWithIcon}>
                <h2>Welcome to GraspLingo</h2>
                <div className="form-main">
                    <form className="contact-inform form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-control">
                            <input {...register('email', { required: true, validate: validateEmail })} type="text" className="form-input" autoComplete="off" />
                            <label htmlFor="email" className="form-label">Email</label>
                            {errors.email?.type === 'required' && <p className="text-danger">Email is required!</p>}
                            {errors.email?.type === 'validate' && <p className="text-danger">Email format is not correct!</p>}
                        </div>
                        <div className="form-control">
                            <input {...register('password', { required: true, minLength: 6 })} type={showPassword ? 'text' : 'password'} className="form-input" autoComplete="off" />
                            <label htmlFor="password" className="form-label">Password</label>
                            <a href="/" onClick={(event) => { event.preventDefault(); toggleShowPassword(); }}><img src={eyeImage} alt="" /></a>
                            {errors.password?.type === 'required' && <p className="text-danger">Password is required!</p>}
                            {errors.password?.type === 'minLength' && <p className="text-danger">Password must be atleast 6 characters!</p>}
                            <br />
                        </div>
                        <div className="fp-parent">
                            <a href="/" onClick={(event) => navigateTo('/forgotPassword', event)} className="forgot-pass">Forgot Password</a>
                        </div>
                        <button type="submit" className="btn login-button">Login</button>
                    </form>
                </div>
                <p>Don't have an account? <a href="/" onClick={(event) => navigateTo('/signup', event)}>Sign Up</a> here</p>
            </CustomModal>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default Login;