/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HttpService = () => {
    const token = localStorage.getItem('jwtToken');
    axios.defaults.headers.common['Authorization'] = token;

    const navigate = useNavigate();

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('jwtToken');
                navigate('/login');
            }
            return Promise.reject(error);
        }
    );

    const get = (url) => {
        const controller = new AbortController();
        const signal = controller.signal;

        const request = axios.get(url, { signal });

        request.abort = () => {
            controller.abort();
        };

        return request;
    }

    const post = (url, data, headers = {}) => {
        return axios.post(url, data, {
            headers: {
                ...headers
            }
        });
    }

    const put = (url, data) => {
        return axios.put(url, data);
    }

    const remove = (url) => {
        return axios.delete(url);
    }

    return {
        get,
        post,
        put,
        remove
    };
}


export default HttpService;