import React from 'react';
import wordImage from '../assets/images/world.png'

const Home = () => {
    return (
        <>
            <section className="login-world-map">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>GraspLinguo,</h2>
                            <h2>Your Language Teaching Coach</h2>
                            <p className="first-para">
                                Clear communication is key to our success, socially, vocationally, and professionally. At Grasp Lingo, our goal is to bridge the communication gap throughout the world by adding clarity to conversations which will in return, create more peace and happiness to all of us. To help us achieve this goal, our Team offers an affordable, productive, and fun method to assist you in this journey while at work, home, school or simply on-the-go!
                            </p>
                            <p>
                                With customizable approaches to learning, we don't want you to only learn the language of your choice, we want you to GRASP it! Join the journey and sign up today, we look forward to you Grasping your language.
                            </p>
                        </div>
                        <div className="col-md-6 login-world-globe">
                            <div className="world-img">
                                <img src={wordImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;