import { useState } from "react";
import LessonsFailRate from "./LessonsFailRate";
import UserLessonDetails from "./UserLessonDetails";
const LanguageTraining = () => {

    const [lesson, setLesson] = useState('');
    const [word, setWord] = useState('');
    return (
        <>
            <LessonsFailRate setLesson={setLesson} setWord={setWord} />
            <UserLessonDetails lesson={lesson} word={word} />
        </>
    );
};

export default LanguageTraining;