import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import leftArrowImg from '../assets/images/left-arrow.svg';
import rightArrowImg from '../assets/images/right-arrow.svg';

const Pagination = ({ pageNo, size, count, perPageItems, onChange, addExtraClasses = true, classes = "" }) => {

    const [pageNumber, setPageNumber] = useState(pageNo);
    const [pageSize, setPageSize] = useState(size);
    const [totalCount, setTotalCount] = useState(count);

    useEffect(() => {
        setPageNumber(pageNo);
        setPageSize(size);
        setTotalCount(count);
    }, [pageNo, size, count])

    const handlePerPageCount = (pageSize) => {
        setPageSize(pageSize);
        onChange(pageNumber, pageSize);
    }

    const handleNextPage = (event) => {
        event.preventDefault();
        var lastPage = Math.ceil(totalCount / pageSize);
        if (pageNumber < lastPage) {
            setPageNumber(pageNumber + 1);
            onChange(pageNumber + 1, pageSize);
        }
    }

    const handlePreviousPage = (event) => {
        event.preventDefault();
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            onChange(pageNumber - 1, pageSize);
        }
    }

    const handleFirstPage = (event) => {
        event.preventDefault();
        if (pageNumber > 1) {
            setPageNumber(1);
            onChange(1, pageSize);
        }
    }

    const handleLastPage = (event) => {
        event.preventDefault();
        if (totalCount > pageSize) {
            var lastPage = Math.ceil(totalCount / pageSize);
            setPageNumber(lastPage);
            onChange(lastPage, pageSize);
        }
    }

    const handlePage = (pageNumber) => {
        setPageNumber(pageNumber);
        onChange(pageNumber, pageSize);
    }

    return (count > 0 &&
        <section className={`${addExtraClasses ? 'pagination-box-main add-suggested-lessonsMain' : ''}`}>
            <div className="container inner-container">
                <div className="row">
                    <div className={`col-md-12 pagination-main add-suggested-popup-pagination ${classes} ${addExtraClasses ? 'pagination-lesson-main pagination-main-two' : ''}`}>
                        <div className="dropdown-body">
                            <span>
                                Items per page:
                            </span>
                            <div className="dropdown">
                                <a className="btn btn-secondary dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {pageSize}
                                </a>
                                <ul className="dropdown-menu">
                                    {perPageItems.map((value, index) => (
                                        <Link key={index}
                                            className={`dropdown-item ${pageSize === value ? ' active' : ''}`}
                                            onClick={() => { handlePerPageCount(value) }}
                                            to="#">
                                            {value}
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="pagination-box">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link page-link-left" href="/" aria-label="Previous" onClick={(event) => { handleFirstPage(event) }}>
                                            <img src={leftArrowImg} alt="" className="l-arrow" />
                                            <img src={leftArrowImg} alt="" />
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="/" onClick={(event) => { handlePreviousPage(event) }}>
                                            <img src={leftArrowImg} alt="" />
                                        </a>
                                    </li>
                                    <li><span>Page</span></li>
                                    <div className="dropdown-items">
                                        <a className="btn btn-secondary dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {pageNumber}
                                        </a>
                                        <ul className="dropdown-menu word-dashboard-dropdown-scroll">
                                            {[...Array(Math.ceil(totalCount / pageSize))].map((_, index) => (
                                                <Link key={index}
                                                    className={`dropdown-item ${pageNumber === index + 1 ? ' active' : ''}`}
                                                    onClick={() => { handlePage(index + 1) }}
                                                    to="#">
                                                    {index + 1}
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                    <li><span>of {Math.ceil(totalCount / pageSize)}</span></li>
                                    <li className="page-item">
                                        <a className="page-link" href="/" onClick={(event) => { handleNextPage(event) }}>
                                            <img src={rightArrowImg} alt="" />
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link page-link-right" href="/" onClick={(event) => { handleLastPage(event) }}>
                                            <img src={rightArrowImg} alt="" className="R-arrow" />
                                            <img src={rightArrowImg} alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pagination;