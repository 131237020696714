import React, { useState } from 'react';
import useHttpService from '../HttpService';
import ReactJson from 'react-json-pretty';
import AudioRecorder from './AudioRecorder';
import LoadingSpinner from './LoadingSpinner';

const SpeechToTextAnalysis = () => {
    const [originalParagraph, setOriginalParagraph] = useState('');
    const [transcribedParagraph, setTranscribedParagraph] = useState('');
    const [analysisResult, setAnalysisResult] = useState(null);
    const [startLesson, setStartLesson] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const httpService = useHttpService();

    const handleAnalysis = async () => {
        try {
            setIsLoading(true)
            var requestObj = {
                original: originalParagraph,
                spoken: transcribedParagraph,
            };
            const response = await httpService.post('api/audio/analysis', requestObj);
            setIsLoading(false)
            console.log(response)
            setAnalysisResult(response);
        } catch (error) {
            console.error('Error analyzing transcription:', error);
            setIsLoading(false)
        }
    };
    const handleSave = async () => {

    }

    return (
        <div className="container mt-5">
            <div className="row align-items-md-stretch">
                <div className="col-md-6">
                    <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                        <h2 style={{ color: 'black' }}>Speech Analysis</h2>

                        <div className="mb-3">
                            <textarea
                                id="originalParagraph"
                                className="form-control"
                                value={originalParagraph}
                                onChange={(e) => setOriginalParagraph(e.target.value)}
                                placeholder="Type Your Lesson"
                                style={{ minHeight: "calc(1.5em + 8.75rem + calc(var(--bs-border-width) * 2))" }}
                            />
                        </div>

                        {startLesson === false && (
                            <div className="text-center mb-3">
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() => setStartLesson(true)}
                                >
                                    Start
                                </button>
                            </div>
                        )}
                        {startLesson === true && (<div className="stop-buttonn">
                            <AudioRecorder
                                handleSave={(transcribedText, transcriptionTime) => handleSave(transcribedText, transcriptionTime)}
                                handleStartLesson={setStartLesson}
                                handlelivetranscription={setTranscribedParagraph}
                            />
                        </div>
                        )}

                        <div className="mb-3">
                            <textarea
                                id="transcribedParagraph"
                                className="form-control"
                                value={transcribedParagraph}
                                onChange={(e) => setTranscribedParagraph(e.target.value)}
                                style={{ minHeight: "calc(1.5em + 8.75rem + calc(var(--bs-border-width) * 2))" }}
                            />
                        </div>

                        <button className="btn btn-outline-primary" onClick={handleAnalysis}>
                            Analyze
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="h-100 p-5 bg-body-tertiary border rounded-3" style={{ maxHeight: '800px', overflowY: 'auto' }}>
                        <h2 style={{ color: 'black' }}>Analysis Result</h2>
                        <ReactJson
                            data={analysisResult}  
                            theme="monikai"  
                            style={{ 
                                backgroundColor: '#f8f9fa',
                                fontSize: '14px',
                                padding: '10px',
                                borderRadius: '4px',
                                overflowY: 'auto'
                            }}
                        />
                    </div>
                </div>
            </div>
            {isLoading && <LoadingSpinner />}
        </div>
    );
};

export default SpeechToTextAnalysis;
