import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import UserLesson from './components/UserLesson';
import AllLessons from './components/AllLessons';
import UserLessons from './components/UserLessons';
import LessonAdmin from './components/Admin/LessonAdmin';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';
import WordAdmin from './components/Admin/WordAdmin';
import Transcribe from './components/Transcribe';
import AudioUploader from './components/AudioUploader';
import AnalysisGPT from './components/AnalysisGPT';
import AnalysisGemini from './components/AnalysisGemini';
import LanguageTraining from './components/Admin/LanguageTraining';
import AIModels from './components/Admin/AIModels';

const App = () => {
    return (
        <Layout>
            <ErrorBoundary>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="/userLesson/:id" element={<ProtectedRoute><UserLesson /></ProtectedRoute>} />
                    <Route path="/allLessons" element={<ProtectedRoute><AllLessons /></ProtectedRoute>} />
                    <Route path="/userLessons" element={<ProtectedRoute><UserLessons /></ProtectedRoute>} />
                    <Route path="/lessonAdmin" element={<AdminProtectedRoute><LessonAdmin /></AdminProtectedRoute>} />
                    <Route path="/wordAdmin" element={<AdminProtectedRoute><WordAdmin /></AdminProtectedRoute>} />
                    <Route path="/transcribe" element={<AdminProtectedRoute><Transcribe /></AdminProtectedRoute>} />
                    <Route path="/languagetraining" element={<AdminProtectedRoute><LanguageTraining /></AdminProtectedRoute>} />
                    <Route path="/audio" element={<AdminProtectedRoute><AudioUploader /></AdminProtectedRoute>} />
                    <Route path="/GPT" element={<AdminProtectedRoute><AnalysisGPT /></AdminProtectedRoute>} />
                    <Route path="/gemini" element={<AdminProtectedRoute><AnalysisGemini /></AdminProtectedRoute>} />
                    <Route path="/aimodels" element={<AdminProtectedRoute><AIModels /></AdminProtectedRoute>} />

                </Routes>
            </ErrorBoundary>
        </Layout>
    )
}

export default App;