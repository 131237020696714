import { useForm } from 'react-hook-form';
import useHttpService from '../HttpService';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import CustomModal from './CustomModal';
import LoadingSpinner from './LoadingSpinner';
import Home from './Home';
import { toast } from "react-toastify";
import eyeImage from '../assets/images/eye.svg';
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const CreateNewPassword = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const httpService = useHttpService();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeWithIcon = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const onSubmit = (data) => {
        const changePasswordToken = localStorage.getItem('verifyCodeToken');

        if (!changePasswordToken) {
            toast.success('You need to verify code first!')
            return;
        }

        var resetPassObj = {
            token: changePasswordToken,
            newPassword: data.password,
            confirmPassword: data.confirmPassword
        };
        setIsLoading(true);
        httpService.post('/api/accounts/resetPassword', resetPassObj)
            .then(() => {
                toast.success('Password has been reset successfully.')
                localStorage.removeItem('verifyCodeToken')
                closeModal();
                navigate('/');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const validateConfirmPassword = (value) => {
        if (value !== watch('password')) {
            return 'Passwords do not match';
        }
        return true;
    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Home />
            <CustomModal isOpen={isModalOpen} closeModal={closeModal} closeWithIcon={closeWithIcon}>
                <h2>Create New Password</h2>
                <div className="form-main">
                    <p>Your password must be differnet from previously used password.</p>
                    <form className="contact-inform form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-control">
                            <input {...register('password', { required: true, minLength: 6 })} type={showPassword ? 'text' : 'password'} className="form-input" />
                            <label htmlFor="email" className="form-label">Password</label>
                            <a href="/" onClick={(event) => { event.preventDefault(); toggleShowPassword(); }}><img src={eyeImage} alt="" /></a>
                            {errors.password?.type === 'required' && <p className="text-danger">Password is required!</p>}
                            {errors.password?.type === 'minLength' && <p className="text-danger">Password must be atleast 6 characters!</p>}
                        </div>
                        <div className="form-control">
                            <input {...register('confirmPassword', { required: true, validate: validateConfirmPassword })} type={showConfirmPassword ? 'text' : 'password'} className="form-input" />
                            <label htmlFor="password" className="form-label">Confirm Password</label>
                            <a href="/" onClick={(event) => { event.preventDefault(); toggleShowConfirmPassword(); }}><img src={eyeImage} alt="" /></a>
                            {errors.confirmPassword?.type === 'required' && <p className="text-danger">Confirm Password is required!</p>}
                            {errors.confirmPassword?.type === 'validate' && <p className="text-danger">Passwords do not match!</p>}
                        </div>
                        <button type="submit" className="btn login-button send-button">Save</button>
                    </form>
                </div>
            </CustomModal>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default CreateNewPassword;