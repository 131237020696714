import React from 'react';

const HighlightLessonIssues = ({ paragraph, wordsToHighlight }) => {
    const highlightWords = (text) => {
        const words = text.split(' ');
        return words.map((word, index) => {
            var wordWithoutPunctuation = removePunctuation(word);
            return (
                <span key={index} style={{ color: wordsToHighlight.includes(wordWithoutPunctuation.toLowerCase()) ? 'red' : '' }}>
                    {`${word} `}
                </span>
            );
        });
    };

    return <span>{highlightWords(paragraph)}</span>;
};

const removePunctuation =(word) => {
    return word.replace(/^[^\w\s]+|[^\w\s]+$/g, '');
}

export default HighlightLessonIssues;
