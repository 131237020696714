import React from 'react';
import { Modal } from 'react-bootstrap';
import cross from '../assets/images/gray-cross.svg'

const ShowModal = ({ showModal, handleCloseModal, children, AddClass = '' }) => {
    return (
        <Modal className={`modal-login work-terminlogy production-popup-main ${AddClass}`} show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
                <div className="close-btn">
                    <button type="button" onClick={handleCloseModal}>
                        <img src={cross} alt="" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default ShowModal;
