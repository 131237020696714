import jwt_decode from 'jwt-decode'
import difficultyOneImg from './assets/images/green-dot.svg';
import difficultyTwoImg from './assets/images/blue-dots.svg';
import difficultyThreeImg from './assets/images/mustard-dots.svg';
import difficultyFourImg from './assets/images/brown-dots.svg';
import difficultyFiveImg from './assets/images/red-dots.svg';
import englishImage from './assets/images/english.svg';
import espanolImage from './assets/images/espanol.svg';

export const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!value || !emailRegex.test(value)) {
        return 'Invalid email address';
    }
    return true;
};

export const getLoggedInUserId = () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
        const decodedToken = jwt_decode(token);
        return decodedToken.nameid;
    }
    return 0;
}

export const MediaBaseURL = 'https://linguo-prod.s3.amazonaws.com/';

export const calculateResult = (percentage) => {
    let grade;

    switch (true) {
        case percentage >= 90:
            grade = 'A+';
            break;
        case percentage >= 80:
            grade = 'B';
            break;
        case percentage >= 70:
            grade = 'C';
            break;
        case percentage >= 60:
            grade = 'D';
            break;
        default:
            grade = 'F';
            break;
    }

    return grade;
}

export const lessonFilters = [
    { name: 'Beginner', value: 1 },
    { name: 'Intermediate', value: 2 },
    { name: 'Advanced', value: 3 },
    { name: 'Experienced', value: 4 },
    { name: 'Master', value: 5 }
]

export const userLessonFileter = [
    { name: "Completed", value: "Completed", type: "status" },
    { name: "InProgress", value: "InProgress", type: "status" },
    { name: "NotStarted", value: "NotStarted", type: "status" },
    { name: "Beginner", value: "1", type: "levelId" },
    { name: "Intermediate", value: "2", type: "levelId" },
    { name: "Advanced", value: "3", type: "levelId" },
    { name: "Experienced", value: "4", type: "levelId" },
    { name: "Master", value: "5", type: "levelId" },
]

export const levelColor = (levelId) => {
    let className = 'numbers';

    switch (levelId) {
        case 1:
            className += ' green';
            break;
        case 2:
            className += ' blue';
            break;
        case 3:
            className += ' purple';
            break;
        case 4:
            className += ' orange';
            break;
        case 5:
            className += ' red';
            break;
        default:
            break;
    }

    return className;
}

export const stateBackground = {
    New: 'yelloww',
    Published: 'greenn',
    Unpublished: 'unpublished',
    MissingLanguage: 'missing-language',
    Missing: 'red'
}

export const levelDots = {
    1: '',
    2: 'blue',
    3: 'musterd',
    4: 'brown',
    5: 'redd'
}

export const countryData = [
    { value: 'en', label: 'English', image: englishImage }
];

export const difficultyData = [
    { value: '1', label: '1', image: difficultyOneImg },
    { value: '2', label: '2', image: difficultyTwoImg },
    { value: '3', label: '3', image: difficultyThreeImg },
    { value: '4', label: '4', image: difficultyFourImg },
    { value: '5', label: '5', image: difficultyFiveImg },
]

export const languageData = [
    { value: '1', label: 'English', image: englishImage },
    { value: '2', label: 'Espanol', image: espanolImage }
]

export const levelDotsImages = {
    1: difficultyOneImg,
    2: difficultyTwoImg,
    3: difficultyThreeImg,
    4: difficultyFourImg,
    5: difficultyFiveImg
}

export const Status = [
    { value: "New", label: 'New' },
    { value: "Published", label: 'Published' },
    { value: "Unpublished", label: 'Unpublished' }
]

export const userLessonFileters = [
    { name: "1", value: "1", type: "levelId", image: difficultyOneImg },
    { name: "2", value: "2", type: "levelId", image: difficultyTwoImg },
    { name: "3", value: "3", type: "levelId", image: difficultyThreeImg },
    { name: "4", value: "4", type: "levelId", image: difficultyFourImg },
    { name: "5", value: "5", type: "levelId", image: difficultyFiveImg },
    { name: "Completed", value: "Completed", type: "status" },
    { name: "In Progress", value: "InProgress", type: "status" },
    { name: "Not Started", value: "NotStarted", type: "status" }
]


export const addSpaceAroundHyphen = (data) => {
    if (Array.isArray(data)) {
        return data.map(str => str.replace(/-/g, ' - '));
    } else if (typeof data === 'object') {
        const modifiedObject = {};
        for (const key in data) {
            if (typeof data[key] === 'string') {
                modifiedObject[key] = data[key].replace(/-/g, ' - ');
            } else {
                modifiedObject[key] = data[key];
            }
        }
        return modifiedObject;
    } else {
        throw new Error('Input is not an object or array.');
    }
}