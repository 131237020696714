import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import ReactJsonPretty from 'react-json-pretty';

import 'react-json-pretty/themes/monikai.css';

const Transcribe = () => {
    const [response, setResponse] = useState({});
    const formik = useFormik({
        initialValues: {
            audioFile: null,
        },
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('audio', values.audioFile);
            var apikey = 'f883f86fa54ac94c79fd47018b1d0c9a46574fd8';

            try {
                const response = await axios.post(
                    'https://api.deepgram.com/v1/listen?smart_format=true&punctuate=true&paragraphs=true&utterances=true&utt_split=0.8&redact=pci&redact=ssn&redact=numbers&diarize=true&summarize=v2&detect_topics=true&language=en&model=whisper-medium',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Token ${apikey}`,
                        },
                    }
                );
                setResponse(response.data);
                console.log('Transcription Result:', response.data);
            } catch (error) {
                console.error('Transcription Error:', error);
            }
        },
    });

    return (
        <>
            <h1>Deepgram Transcription App</h1>
            <form onSubmit={formik.handleSubmit}>
                <input
                    type="file"
                    accept="audio/*"
                    onChange={(event) =>
                        formik.setFieldValue('audioFile', event.currentTarget.files[0])
                    }
                />
                <button type="submit">Transcribe</button>
            </form>
            <br />
            <ReactJsonPretty
                id="json-pretty"
                json={response}
            />
        </>
    );
};

export default Transcribe;
