import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useHttpService from "../HttpService";
import Autocomplete from "./AutoComplete"
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const SearchLessons = ({ onLessonSelect }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedLessonId, setSelectedLessonId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const httpService = useHttpService();
    const pageItems = [10, 20, 30];
    const lessonRequestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        SearchText: "",
        levelId: null,
        state: null,
        Word: "",
    };

    useEffect(() => {
        const fetchSuggestions = async () => {
            lessonRequestObj.SearchText = searchTerm;
            try {
                const response = await httpService.post(
                    "/api/lessons/all",
                    lessonRequestObj
                );
                setSuggestions(response.data.data);
            } catch (err) {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                } else {
                    setErrorMessage(
                        "Something went wrong, please try again or contact support!"
                    );
                }
                setShowModal(true);
                return;
            }
        };

        if (searchTerm.trim() !== "") {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchTerm]);

    const handleSuggestionClick = (value, lessonId) => {
        setSelectedLesson(value);
        setSelectedLessonId(lessonId);
        setSearchTerm(value);
        setSuggestions([]);
        onLessonSelect(lessonId);
    };

    const handleSearchTermChange = (e) => {
        onLessonSelect(0);
        setSearchTerm(e.target.value);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <div className="lesson-forms-selectors lesson-forms">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Lessons"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
                {suggestions.length > 0 && (
                    <Autocomplete
                        suggestions={suggestions}
                        onSuggestionClick={handleSuggestionClick}
                    />
                )}
            </div>
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    );
};

export default SearchLessons;
