import React from 'react';
import tickImage from '../assets/images/tick.svg';
import crossImage from '../assets/images/cross.svg';
import polyImage from '../assets/images/polygon-one.png';

class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.inputValue,
      position: { x: 0, y: 0 }
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  setPopoverPosition = (e) => {
    this.setState({
      position: {
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  handleClickOutside = (e) => {
    const { onClose } = this.props;
    const isOutside = !this.popoverRef.contains(e.target);
    if (isOutside) {
      onClose();
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { inputValue } = this.state;
    onSave(inputValue);
  };

  handleChange = (e) => {
    this.setState({
      inputValue: e.target.value
    });
  };

  render() {
    const { position, inputValue } = this.state;

    return (
      <div
        className="popover-content-main"
        style={{ position: 'absolute', top: position.y, left: position.x }}
        ref={(ref) => (this.popoverRef = ref)}
      >
        <div className="lesson-forms">
          <input
            type="text"
            className="form-control"
            id="text-word"
            placeholder="input"
            value={inputValue}
            onChange={this.handleChange}
          />
        </div>
        <div className="close-tick">
          <div className="red-cross">
            <a href="/" onClick={(e) => { e.preventDefault(); this.handleClose(); }}>
              <img src={crossImage} alt="" id="popoverCross" className="popover-cross" />
            </a>
          </div>
          <div className="green-tick">
            <a href="/" onClick={(e) => { e.preventDefault(); this.handleSave(); }}>
              <img src={tickImage} alt="" />
            </a>
          </div>
        </div>
        <img src={polyImage} alt="" className="polygone-shape polygone-shape-one" />
      </div>
    );
  }
}

export default Popover;
