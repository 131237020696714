import Contact from "./components/Contact";
import CreateNewPassword from "./components/CreateNewPassword";
import ForgotPassword from "./components/ForgotPassword";
import Home from "./components/Home";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import VerifyCode from "./components/VerifyCode";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/contact',
        element: <Contact />
    },
    {
        path: '/signup',
        element: <SignUp />
    },
    {
        path: '/forgotPassword',
        element: <ForgotPassword />
    },
    {
        path: '/verifyCode',
        element: <VerifyCode />
    },
    {
        path: '/resetPassword',
        element: <CreateNewPassword />
    }
];

export default AppRoutes;
