import React, { useContext, useEffect, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { AuthContext } from '../AuthContext';
import logo from '../assets/images/logo.svg';
import profile from '../assets/images/login-bg-screen.svg';
import lessonsImg from '../assets/images/lessons-img.svg';
import wordImg from '../assets/images/word-img.svg';
import dashboardIcon from '../assets/images/dashbord-icon.svg';
import trainingAuditIcon from '../assets/images/training-audit.svg';

const NavMenu = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const [userName, setUserName] = useState('');
    const [role, setRole] = useState('User');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        if (token && isLoggedIn) {
            const decodedToken = jwt_decode(token);
            setUserName(decodedToken.unique_name);
            setRole(decodedToken.role);
        }
    }, [isLoggedIn]);

    const logout = () => {
        localStorage.removeItem('jwtToken')
        setIsLoggedIn(false);
        navigate('/')
    };

    return (
        <header className={`binguo-main ${isLoggedIn ? 'dashbord-main' : ''}`}>
            <nav className="navbar navbar-expand-lg navbar-light headerNav">
                <div className="container white_header_bg">
                    <a className="navbar-brand" href="/"><img src={logo} alt=""></img></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse binguo-header-main ${isLoggedIn && role === 'Admin' ? 'dashbordsLeft-border' : ''}`}>
                        <ul className={`navbar-nav ms-auto ${isLoggedIn && role === 'Admin' ? 'admin-dashbord-navMain' : 'user-dashbord-navMain'}`}>

                            {isLoggedIn && <li className="user-dashbord">
                                <div className="admin-dashbord-navbar-main cursor" onClick={() => { navigate('/dashboard'); }}>
                                    <div className="admin-dashbord-image">
                                        <Link>
                                            <img src={dashboardIcon} alt="" />
                                        </Link>
                                    </div>
                                    <Link to="/dashboard" className="nav-link">
                                        <span>Lessons</span>
                                    </Link>
                                </div>
                            </li>}
                            {!isLoggedIn && <li></li>}

                            {isLoggedIn && role === 'Admin' &&
                                <>
                                    <li>
                                        <div className="admin-dashbord-navbar-main cursor" onClick={() => { navigate('/lessonAdmin'); }}>
                                            <div className="admin-dashbord-image">
                                                <Link>
                                                    <img src={lessonsImg} alt="" />
                                                </Link>
                                            </div>
                                            <Link to="/lessonAdmin" className="nav-link">
                                                <span>Lesson Admin</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="admin-dashbord-navbar-main cursor" onClick={() => { navigate('/wordAdmin'); }}>
                                            <div className="admin-dashbord-image">
                                                <Link>
                                                    <img src={wordImg} alt="" />
                                                </Link>
                                            </div>
                                            <Link to="/wordAdmin" className="nav-link">
                                                <span>Word Admin</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="admin-dashbord-navbar-main cursor" onClick={() => { navigate('/languageTraining'); }}>
                                            <div className="admin-dashbord-image">
                                                <Link>
                                                    <img src={trainingAuditIcon} alt="" />
                                                </Link>
                                            </div>
                                            <Link to="/languageTraining" className="nav-link">
                                                <span>Training Audit</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="admin-dashbord-navbar-main cursor" onClick={() => { navigate('/aimodels'); }}>
                                            <div className="admin-dashbord-image">
                                                <Link>
                                                    <img src={wordImg} alt="" />
                                                </Link>
                                            </div>
                                            <Link to="/aimodels" className="nav-link">
                                                <span>AI Models</span>
                                            </Link>
                                        </div>
                                    </li>
                                </>}
                            {role !== 'Admin' &&
                                <NavItem>
                                    <NavLink tag={Link} className="nav-decoration" to="/contact">Contact Us</NavLink>
                                </NavItem>}
                            <NavItem>
                                {!isLoggedIn && <button type="button" className="btn" onClick={() => { navigate('/login') }}><NavLink tag={Link} className="nav-decoration" to="/login">Login</NavLink></button>}
                                {isLoggedIn &&
                                    <>
                                        <div className="dropdown login-menu">
                                            <div className="login-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={profile} alt="" />
                                                <span>{role === 'User' ? userName : 'Admin'}</span>
                                            </div>
                                            <ul className="dropdown-menu">
                                                <li><NavLink tag={Link} className="dropdown-item dd-item-clr" to="/" onClick={logout}>Log out</NavLink></li>
                                            </ul>
                                        </div>
                                    </>}
                            </NavItem>
                        </ul>
                    </div>

                </div>
            </nav>
        </header>
    );
}

export default NavMenu;
