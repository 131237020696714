/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useHttpService from "../../HttpService";
import exclamationImage from "../../assets/images/exclamation.png";
import saveImage from "../../assets/images/floopy-disk.svg";
import tickImage from "../../assets/images/tick.svg";
import LoadingSpinner from '../LoadingSpinner';
import Pagination from "../Pagination";
import { calculateResult, MediaBaseURL } from "../../HelperMethods";
import Tooltip from "../Tooltip";
import AudioPlayer from "../AudioPlayer";
import ShowModal from "../ShowModal";
import TranscribedTextWithIssues from "../TranscribedTextWithIssues";
import ErrorMessage from "../../assets/images/error-mesgg.svg";
import { toast } from "react-toastify";

const UserLessonDetails = ({ lesson, word }) => {
    const [userLessons, setUserLessons] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [transcribedText, setTranscribedText] = useState("");
    const [audioUrl, setAudioUrl] = useState("");
    const [lessonParagraph, setLessonParagraph] = useState("");
    const [issues, setIssues] = useState([]);
    const [filtersSelected, setFiltersSelected] = useState(false);
    const [updatedWords, setUpdatedWords] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errShowModal, setErrShowModal] = useState(false);


    const pageItems = [10, 20, 30];
    const httpService = useHttpService();
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        Word: "",
        LessonName: "",
        UserName: "",
    };
    
    useEffect(() => {
        if (lesson.length > 0) {
            document.getElementById("word").value = "";
            document.getElementById("lesson").value = lesson;
            setFiltersSelected(true);
        }
        if (word.length > 0) {
            document.getElementById("lesson").value = "";
            document.getElementById("word").value = word;
            setFiltersSelected(true);
        }
        GetAllLessons(requestObj.PageNumber, requestObj.PageSize);
    }, [lesson, word]);
    const GetAllLessons = (pageNumber, pageSize) => {
        setIsLoading(true);
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        requestObj.Word = document.getElementById("word")?.value;
        requestObj.LessonName = document.getElementById("lesson")?.value;
        requestObj.UserName = document.getElementById("user")?.value;

        httpService.post('/api/userLessons/all-userLessons', requestObj)
            .then(res => {
                setUserLessons(res.data.data);
                setTotalCount(res.data.totalCount);
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false));
    };
    const getWordIssues = (issues) => {
        const firstFourWords = issues.slice(0, 4).map(issue => issue.wordName);
        return firstFourWords.join(", ");
    }

    const getRestWordCount = (issues) => {
        return Math.max(0, issues.length - 4);
    }
    const getTotalWordCount = (issues) => {
        return Math.max(0, issues.length);
    }

    const getWordIssuesRemaining = (issues) => {
        const remainingWords = issues.slice(4).map(issue => issue.wordName);
        return remainingWords.length > 0 ? remainingWords.join(", ") : "";
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleLessonModal = (userLesson) => {
        setShowModal(true);
        setTranscribedText(userLesson.speechText);
        setLessonParagraph(userLesson.lessonParagraph);
        setIssues(userLesson.issues);
        if (userLesson.speechAudioPath !== '') {
            setAudioUrl(MediaBaseURL + userLesson.speechAudioPath);
        }
        else {
            setAudioUrl("");
        }
    }
    const handleFilterChange = () => {
        setFiltersSelected(true);
    };
    const handleClearResults = (event) => {
        event.preventDefault();
        requestObj.Word = "";
        requestObj.LessonName = "";
        requestObj.UserName = "";

        document.getElementById("word").value = "";
        document.getElementById("lesson").value = "";
        document.getElementById("user").value = "";

        GetAllLessons(requestObj.PageNumber, requestObj.PageSize);
        setFiltersSelected(false);
    };

    const saveIssues = (words) => {
        httpService.post('/api/userLessons/resolve-issues', words)
            .then(res => {
                toast.success('Issues resolved successfully!');
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setErrShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setErrShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setErrShowModal(true);
                }
            });
    };
    

    return (
        <>
            <section className="language-training-form">
                <div className="container inner-container">
                    <div className="lessons-input-fielsMain">
                        <div className="wordDashbord-forms">
                            <form>
                                <div className="lesson-forms">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        id="lesson"
                                        placeholder="Lessons"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            GetAllLessons(1, 10);
                                            handleFilterChange();
                                        }}
                                    />
                                </div>
                                <div className="lesson-forms">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        id="word"
                                        placeholder="Words"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            GetAllLessons(1, 10);
                                            handleFilterChange();
                                        }}
                                    />
                                </div>
                                <div className="lesson-forms">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        id="user"
                                        placeholder="Users"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            GetAllLessons(1, 10);
                                            handleFilterChange();
                                        }}
                                    />
                                </div>
                                {filtersSelected && (
                                    <div className="clear-results">
                                        <a href="/" onClick={handleClearResults}>Clear Results</a>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="table-main lang-training-main lang-training-main-two">
                <div className="container inner-container">
                    <div className="table-responsiveness">
                        <div className="table-inside">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Lesson</th>
                                        <th>User</th>
                                        <th>Grade</th>
                                        <th>Wrong Words</th>
                                        <th>Audio</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userLessons.map((userLesson, index) => (
                                        <tr key={index}>
                                            <td style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                {userLesson.lessonName.length > 30 ? (
                                                    <Tooltip text={userLesson.lessonName}>
                                                        {`${userLesson.lessonName.substring(0, 30)}...`}
                                                    </Tooltip>
                                                ) : userLesson.lessonName}
                                            </td>
                                            <td>{userLesson.userName}</td>
                                            <td>{calculateResult(parseInt(userLesson.score))}</td>
                                            <td>
                                                <div className="tab-main-2-span">
                                                    <span>{getWordIssues(userLesson.issues)}</span>
                                                    {getRestWordCount(userLesson.issues) > 0 &&
                                                        <Tooltip text={getWordIssuesRemaining(userLesson.issues)}>
                                                            <a href="/" onClick={(e) => e.preventDefault()} className="three-more" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Lorem, ipsum, dolor, pharetra, blandit, elit, tincidunt">
                                                                +{getRestWordCount(userLesson.issues)} more
                                                            </a>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <AudioPlayer audioUrl={userLesson.speechAudioPath ? MediaBaseURL + userLesson.speechAudioPath : ""} />
                                            </td>
                                            <td className="lang-tick-exclamation">
                                                <div className="red-exclamation">
                                                    <a href="/"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleLessonModal(userLesson);
                                                        }}>
                                                        <img src={exclamationImage} alt="" />
                                                    </a>
                                                </div>
                                                {getTotalWordCount(userLesson.issues) === 0 &&
                                                    <div className="green-tick">
                                                        <a href="/" onClick={(e) => { e.preventDefault();}}>
                                                            <img src={tickImage} alt="" />
                                                        </a>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <ShowModal showModal={showModal} handleCloseModal={handleCloseModal} AddClass="Lang-training-modal fade">
                    <div className="modal-body">
                        <div className="lang-training-left">
                            <h2>Lesson Text</h2>
                            <p>{lessonParagraph}
                            </p>
                        </div>
                        <div className="lang-training-right">
                            <TranscribedTextWithIssues
                                transcribedText={transcribedText}
                                issues={issues}
                                referenceText={lessonParagraph}
                                setUpdatedWords={setUpdatedWords}
                                updatedWords={updatedWords}
                            />
                            <div className="lang-btn-container">
                                <AudioPlayer audioUrl={audioUrl} />
                                <a href="/" onClick={(e)=>{e.preventDefault(); saveIssues(updatedWords)}} className="lang-floopy"><img src={saveImage} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
            {userLessons && <Pagination pageNo={requestObj.PageNumber} size={requestObj.PageSize} count={totalCount} perPageItems={pageItems} onChange={GetAllLessons} addExtraClasses={false} classes="main-page-pagination paginaion-left" />}
            {isLoading && <LoadingSpinner />}
            {errShowModal && (<ShowModal showModal={errShowModal} handleCloseModal={handleCloseModal} AddClass={'error-message-mainbox fade'}>
                <div class="error-mesg-main">
                    <div class="del-box">
                        <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div class="continoue">
                            <a href="/" onClick={(e) => {
                                e.preventDefault();
                                setShowModal(false);
                            }}>Continue</a>
                            </div>
                    </div>
                </div>
            </ShowModal>
            )}
        </>
    );
}

export default UserLessonDetails;