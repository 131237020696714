import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const AdminProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('jwtToken');
    const authToken = jwt_decode(token);
    const isAuthenticated = authToken?.role === 'Admin';

    if (!isAuthenticated)
        return <Navigate to="/login" replace />;

    return children;
}

export default AdminProtectedRoute;
