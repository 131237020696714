import { useEffect, useState } from "react";
import cloudsImage from '../../assets/images/clouds.svg'
import editImage from '../../assets/images/edit.svg'
import delImage from '../../assets/images/del.svg'
import CustomModal from '.././CustomModal';
import useHttpService from '../../HttpService';
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { MediaBaseURL } from '../../HelperMethods';
import LoadingSpinner from '../LoadingSpinner';

const Language = ({ showLanguage, handleCloseModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileFlag, setFileFlag] = useState('');
    const httpService = useHttpService();
    const { register, handleSubmit } = useForm();
    const [name, setName] = useState('');
    const [languageId, setlanguageId] = useState('');

    useEffect(() => {
        if (showLanguage) {
            getLanguages();
            resetFields();
        } else {
            setIsModalOpen(false);
        }
    }, [showLanguage]);

    const resetFields = () => {
        setName('');
        setFileFlag(null);
        setlanguageId('');
    };

    const getLanguages = () => {
        setIsLoading(true);
        httpService.get(`/api/languages`)
            .then(res => {
                console.log(res.data);
                setLanguages(res.data);
                resetFields();
                setIsModalOpen(true);
            })
            .catch(err => {
                if (err?.response?.data)
                    toast.error(err.response.data);
                else if (err.errors)
                    toast.error(err.errors[0]);
                else toast.error('Something went wrong, please try again or contact support!');
            })
            .finally(() => setIsLoading(false));
    }

    const handleSubmits = (data) => {
        if (languageId === '') {
            handleSave(data);
        } else {
            handleUpdate(data);
        }
    }

    const handleSave = (data) => {
        if (data.name === "") {
            toast.error('Language name is required!');
        }
        if (fileFlag === undefined) {
            toast.error('Flag is required!');
            return;
        }
        const formData = new FormData();
        formData.append('flagImage', fileFlag);
        var headers = { 'Content-Type': 'multipart/form-data' };
        setIsLoading(true);

        httpService.post(`/api/languages?languageName=${data.name}`, formData, headers)
            .then(res => {
                getLanguages();
                resetFields();
                toast.success('Language added successfully');
            })
            .catch(err => {
                if (err?.response?.data)
                    toast.error(err.response.data);
                else if (err.errors)
                    toast.error(err.errors[0]);
                else
                    toast.error('Something went wrong, please try again or contact support!');
            })
            .finally(() => setIsLoading(false));
    };

    const handleDelete = (event, languageId) => {
        event.preventDefault();
        httpService.remove(`/api/languages?languageId=${languageId}`)
            .then(res => {
                toast.success(`Language deleted successfully.`);
                getLanguages();
            })
            .catch(err => {
                if (err?.response?.data)
                    toast.error(err.response.data);
                else if (err.errors)
                    toast.error(err.errors[0]);
                else toast.error('Something went wrong, please try again or contact support!');
            });
    }

    const handleUpdate = (data) => {
        const formData = new FormData();
        formData.append('flagImage', fileFlag);
        var headers = { 'Content-Type': 'multipart/form-data' };
        setIsLoading(true);
        httpService.put(`/api/languages?languageId=${languageId}&languageName=${data.name}`, formData, headers)
            .then(res => {
                getLanguages();
                resetFields();
                toast.success(`Language updated successfully.`);
            })
            .catch(err => {
                if (err?.response?.data)
                    toast.error(err.response.data);
                else if (err.errors)
                    toast.error(err.errors[0]);
                else toast.error('Something went wrong, please try again or contact support!');
            });
    }
    const closeModal = () => {
        handleCloseModal();
    }

    const onEdit = (event, language) => {
        event.preventDefault();
        resetFields();
        setName(language.name);
        setlanguageId(language.languageId);
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFileFlag(selectedFile);
    };

    return (
        <>
            {isModalOpen && <CustomModal isOpen={isModalOpen} closeWithIcon={closeModal}>
                <>
                    <div className="whiteBox-heading terminology">
                        <h2>Language</h2>
                    </div>
                    <div >
                        <form onSubmit={handleSubmit(handleSubmits)}>
                            <input {...register('name', { required: true })} type="text" className="form-control" placeholder="Language Name" value={name} onChange={(e) => setName(e.target.value)} />

                            <label htmlFor="flagUpload" className="drop-flag-main">
                                <img src={cloudsImage} alt="" />
                                <span>Drag & Drop Flag Icon or <a href="#" className="browse-link" onClick={(e) => { e.preventDefault(); document.getElementById('flagUpload').click(); }}>Browse</a></span>
                            </label>
                            <input {...register('flagUpload', { required: false })} type="file" id="flagUpload" name="myImage" onChange={handleFileChange} style={{ display: 'none' }} />

                            <div className="add-language">
                                <button type="submit" className="btn">Add Language</button>
                            </div>
                        </form>
                        <div className="del-language">
                            <span>Edit or Delete Language</span>
                        </div>
                        <div className="languages-edit-delBox">
                            {languages.map((language) => (
                                <div className="lang-edit-main" key={language.languageId}>
                                    <div className="language-selector">
                                        <img src={MediaBaseURL + language.flagPath} alt="" height="40" width="40" />
                                        <span>{language.name}</span>
                                    </div>
                                    <div className="lang-edit-del">
                                        <div className="editt">
                                            <a href="/" onClick={(event) => { event.preventDefault(); onEdit(event, language) }}>
                                                <img src={editImage} alt="Edit" />
                                            </a>
                                        </div>
                                        <div className="dell">
                                            <a href="/" onClick={(event) => { event.preventDefault(); handleDelete(event, language.languageId) }}>
                                                <img src={delImage} alt="Delete" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            </CustomModal>}
            {isLoading && <LoadingSpinner />}
        </>
    )
}

export default Language;