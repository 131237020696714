import { useEffect, useState } from "react";
import useHttpService from "../../HttpService";

const LessonsFailRate = ({ setLesson, setWord }) => {
    const [, setLessonsFailRate] = useState([]);
    const [, setWordsFailRate] = useState([]);
    const [firstThreeLessons, setFirstThreeLessons] = useState([]);
    const [lastThreeLessons, setLastThreeLessons] = useState([]);
    const [firstThreeWords, setFirstThreeWords] = useState([]);
    const [lastThreeWords, setLastThreeWords] = useState([]);
    const httpService = useHttpService();

    const pageItems = [6, 15, 30];
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
    };

    useEffect(() => {
        getLessonsFailRate(requestObj.PageNumber, requestObj.PageSize);
        getWordsFailRate(requestObj.PageNumber, requestObj.PageSize);
    }, []);

    const getLessonsFailRate = async (pageNumber, pageSize) => {
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        try {
            const response = await httpService.post('/api/lessons/lesson-fail-rate', requestObj);
            setLessonsFailRate(response.data.data);
            setFirstThreeLessons(response.data.data.slice(0, 3));
            setLastThreeLessons(response.data.data.slice(-3));
        } catch (error) {
            console.error("Error fetching lessons fail rate:", error);
        }
    };
    const getWordsFailRate = async (pageNumber, pageSize) => {
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        try {
            const response = await httpService.post('/api/userLessons/word-lesson-fail-rate', requestObj);
            setWordsFailRate(response.data.data);
            setFirstThreeWords(response.data.data.slice(0, 3));
            setLastThreeWords(response.data.data.slice(-3));
        } catch (error) {
            console.error("Error fetching lessons fail rate:", error);
        }
    };

    const lessonClick = (lessonName) => {
        setWord("");
        setLesson(lessonName);
    }
    const wordClick = (word) => {
        setLesson("");
        setWord(word);
    }

    return (
        <>
            <section className="table-main lesson-fail-rate-main">
                <div className="container inner-container">
                    <div className="table-responsiveness">
                        <div className="table-fail-rate-bg tax-fail-bg table-responsiveness">
                            <div className="table-box-main table-box-left">
                                <table className="table-small-boxes">
                                    <thead>
                                        <tr>
                                            <th>Lessons #</th>
                                            <th>Fail Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {firstThreeLessons.map((lesson, index) => (
                                            <tr key={index}>
                                                <td className="lesson-name-length-setting"><a href="/"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        lessonClick(lesson.name);
                                                    }}>
                                                    <span>{lesson.name}</span></a></td>
                                                <td>{lesson.failRate}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="table-small-boxes">
                                    <thead>
                                        <tr>
                                            <th>Lessons #</th>
                                            <th>Fail Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lastThreeLessons.map((lesson, index) => (
                                            <tr key={index}>
                                                <td className="lesson-name-length-setting"><a href="/" onClick={(event) => {
                                                    event.preventDefault();
                                                    lessonClick(lesson.name);
                                                }}>
                                                    <span>{lesson.name}</span></a></td>
                                                <td>{lesson.failRate}%</td>
                                            </tr>
                                        ))}
                                    </tbody >
                                </table >
                            </div>
                            <div className="table-box-main table-box-right">
                                <table className="table-small-boxes">
                                    <thead>
                                        <tr>
                                            <th>Words #</th>
                                            <th>Fail Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {firstThreeWords.map((lesson, index) => (
                                            <tr key={index}>
                                                <td><a href="/" onClick={(event) => {
                                                    event.preventDefault();
                                                    wordClick(lesson.name)
                                                }}>
                                                    <span>{lesson.name}</span></a></td>
                                                <td>{lesson.failRate}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="table-small-boxes">
                                    <thead>
                                        <tr>
                                            <th>Words #</th>
                                            <th>Fail Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lastThreeWords.map((lesson, index) => (
                                            <tr key={index}>
                                                <td><a href="/" onClick={(event) => {
                                                    event.preventDefault();
                                                    wordClick(lesson.name)
                                                }}>
                                                    <span>{lesson.name}</span></a></td>
                                                <td>{lesson.failRate}%</td>
                                            </tr>
                                        ))}
                                    </tbody >
                                </table >
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LessonsFailRate;
