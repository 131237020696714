import React, { useState, useEffect } from "react";
import Popover from "./Popover";

const TranscribedTextWithIssues = ({
  transcribedText,
  issues,
  setUpdatedWords,
  updatedWords,
}) => {
  const [popoverWord, setPopoverWord] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [highlightedText, setHighlightedText] = useState(null);
  const [ind, setIndex] = useState(null);
  const [issueId, setIssueId] = useState(null);

  const handleWordClick = (word, index, key) => {
    var mistakes = issues.map((issue) => issue.pronounced);
    if (mistakes.includes(word.toLowerCase())) {
      setPopoverWord(word);
      setIndex(index);
      setIssueId(key);
      setShowPopover(true);
    } else {
      setPopoverWord(null);
      setIndex(null);
      setShowPopover(false);
    }
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  const handleSetWord = (newWord) => {
    updateHighlightedWord(ind, newWord);
    const updatedWordsCopy = [...updatedWords];
    if (!updatedWordsCopy.find((word) => word.issueId === issueId)) {
      updatedWordsCopy.push({ issueId: issueId, word: newWord });
    } else {
      updatedWordsCopy.forEach((word, index) => {
        if (word.issueId === issueId) {
          updatedWordsCopy[index].word = newWord;
          debugger
        }
      });
    }
    setUpdatedWords(updatedWordsCopy);
    setPopoverWord(null);
    setShowPopover(false);
  };
  const updateHighlightedWord = (key, newValue) => {
    const updated = highlightedText.map((word, index) => {
      if (index === key) {
        return newValue + " ";
      }
      return word;
    });
    setHighlightedText(updated);
  };

  const highlightWords = () => {
    const words = transcribedText.split(" ");
    return words.map((word, index) => {
      const wordWithoutPunctuation = removePunctuation(word);
      var mistakes = issues.map((issue) => issue.pronounced);
      const isIssue = mistakes.includes(wordWithoutPunctuation.toLowerCase());
      const key = isIssue
        ? issues.find((issue) =>
            issue.pronounced.includes(wordWithoutPunctuation.toLowerCase())
          ).issueId
        : null;
      return (
        <span
          key={index}
          style={{
            color: isIssue ? "red" : "",
            cursor: isIssue ? "pointer" : "auto",
          }}
          onClick={() => handleWordClick(word, index, key)}
        >
          {`${word} `}
        </span>
      );
    });
  };

  const removePunctuation = (word) => {
    return word.replace(/^[^\w\s]+|[^\w\s]+$/g, "");
  };

  useEffect(() => {
    setHighlightedText(highlightWords());
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <h2>Transcribed Text</h2>
      <p>{highlightedText}</p>
      {showPopover && (
        <Popover
          inputValue={popoverWord}
          onClose={handleClosePopover}
          onSave={handleSetWord}
        />
      )}
    </div>
  );
};

export default TranscribedTextWithIssues;
