/* eslint-disable react-hooks/exhaustive-deps */
import MicrophoneImage from "../assets/images/allow-your-microphone.png";

const Microphone = () => {
  return (
    <div className="full-screen-blur-main">
      <div className="full-screen-blur"></div>
      <img src={MicrophoneImage} alt="" />
    </div>
  );
};
export default Microphone;
