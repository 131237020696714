import React, { useRef, useState } from 'react';
import useHttpService from '../HttpService'
import CustomModal from './CustomModal';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import Home from './Home';
import { toast } from "react-toastify";
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const VerifyCode = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailCode, setEmailCode] = useState('');
    const inputRefs = useRef([]);
    const httpService = useHttpService();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeWithIcon = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const codeToken = localStorage.getItem('verifyCodeToken');
        const verifyCodeObj = {
            token: codeToken,
            code: emailCode
        };

        if (!codeToken) {
            toast.error('You have not requested a change password!');
            return;
        }

        setIsLoading(true);
        httpService.post('/api/accounts/verifyCode', verifyCodeObj)
            .then(() => {
                toast.success('Code has been verified.');
                closeModal();
                localStorage.removeItem('verifyCodeEmail');
                navigate('/resetPassword');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const resendCode = (event) => {
        event.preventDefault();
        const providedEmail = localStorage.getItem('verifyCodeEmail');
        setIsLoading(true);
        httpService.post('/api/accounts/sendCode?email=' + providedEmail)
            .then(res => {
                toast.success('Code has been resent.');
                localStorage.setItem('verifyCodeToken', res.data);
                alert('Email is resent');
                setIsClicked(true);
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        if (value.length <= 1) {
            setEmailCode((prevCode) => {
                const updatedCode = prevCode.split('');
                updatedCode[index] = value;
                return updatedCode.join('');
            });
            if (index < inputRefs.current.length - 1 && value !== '') {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && emailCode[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const linkStyle = {
        pointerEvents: isClicked ? 'none' : 'auto',
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Home />
            <CustomModal isOpen={isModalOpen} closeModal={closeModal} closeWithIcon={closeWithIcon}>
                <h2>Verify Your Email</h2>
                <div className="form-main">
                    <p>Please enter 4 digit code sent to your email.</p>
                    <div className="verify-code-main">
                        <form onSubmit={handleSubmit}>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <input
                                    className="code-box"
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={emailCode[index] || ''}
                                    onChange={(event) => handleInputChange(index, event)}
                                    onKeyDown={(event) => handleKeyDown(index, event)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    required
                                />
                            ))}
                            <a href="/" className="resend-code" style={linkStyle} onClick={(event) => resendCode(event)}>Resed Code</a>
                            <button type="submit" className="btn login-button send-button">Verify</button>
                        </form>
                    </div>
                </div>
            </CustomModal>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default VerifyCode;