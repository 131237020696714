/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import startImage from '../assets/images/play-btn-white.svg';
import { getLoggedInUserId, levelDotsImages } from '../HelperMethods';
import useHttpService from '../HttpService';
import { toast } from "react-toastify";
import LoadingSpinner from './LoadingSpinner';
import englishImage from '../assets/images/english.svg';
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const SuggestedLessons = ({ lessonId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [suggestedLessons, setSuggestedLessons] = useState([]);
    const httpService = useHttpService();
    const loggedInUserId = getLoggedInUserId();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        httpService.get(`/api/userLessons/${loggedInUserId}/suggested-lessons?userId=${loggedInUserId}&lessonId=${lessonId}`)
            .then(res => {
                setSuggestedLessons(res.data);
            })
            .catch(err => {
                setErrorMessage('Issue loading suggested lessons!')
                setShowModal(true);
                return;
            });
    }, []);

    const handleJoin = (lessonId) => {
        setIsLoading(true);
        httpService.post(`/api/userLessons/join?userId=${loggedInUserId}&lessonId=${lessonId}`)
            .then(res => {
                navigate('/userLesson/' + res.data);
                window.location.reload();
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {suggestedLessons.length > 0 &&(
                <section className="table-main suggested-lessonss">
                    <div className="container inner-container">
                        <h2>Suggested Next Lessons</h2>
                        <div className="table-responsiveness">
                            <div className="table-inside">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Lesson</th>
                                            <th>Est. Time</th>
                                            <th>Level</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {suggestedLessons.length === 0 && <tr><td colSpan="5" style={{ textAlign: 'left' }}>No suggested lessons!</td></tr>}
                                        {suggestedLessons.map(lesson => <tr key={lesson.suggestedLessonId}>{
                                            <>
                                                <td><img src={englishImage} alt="" className="table-image" />{lesson.name}</td>
                                                <td>{lesson.estimatedTime === "" ? 'N/A' : lesson.estimatedTime}</td>
                                                <td><img src={levelDotsImages[lesson.levelId]} alt="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <a className="table-play-btn join-lesson" href="/" onClick={(e) => { e.preventDefault(); handleJoin(lesson.suggestedLessonId); }}>
                                                        <img src={startImage} alt="" />
                                                    </a>
                                                </td>
                                            </>
                                        }</tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}

export default SuggestedLessons;