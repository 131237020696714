import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('jwtToken');
    const isAuthenticated = !!token;

    if (!isAuthenticated)
        return <Navigate to="/login" replace />;

    return children;
}

export default ProtectedRoute;
